import flatMap from 'lodash-es/flatMap';
import map from 'lodash-es/map';
import reduce from 'lodash-es/reduce';
import { CartItemWithProductVariantSize } from '@/types/cart';
import { AddToCartPayload, Metrics, MetricsContext, PurchasePayload, RemoveFromCartPayload } from '@/types/metrics';
import { BasicProductFragment, ProductFragment } from '@/gql';
import { pushToDataLayer } from '@/lib/gtm/pushToDataLayer';
import { parseSku } from '../product';

type EventKeys = {
  [K in keyof Metrics]: string;
};

const eventKeys: EventKeys = {
  purchase: 'purchase',
  add_to_cart: 'add-to-cart',
  remove_from_cart: 'remove-from-cart',
  view_item: 'product-detail-view',
  quickview_viewed: 'product-detail-view',
  error_viewed: 'error_viewed',
  select_item: 'product-click',
  begin_checkout: 'begin-checkout',
  add_shipping_info: 'add_shipping_info',
  add_payment_info: 'add-payment-info',
  view_cart: 'view-cart',
};

const replaceLowDashesWithDashes = (aString: string | null | undefined) => aString?.replace(/_/g, '-') || '';

export const convertToDollars = (amountInCents: number) => {
  return (Math.round(amountInCents) / 100).toFixed(2);
};

const skuConstructor = (
  listingId: string | number,
  productId: string | number,
  variationId: string | number,
  sizeId: string | number
) => `${listingId}-${productId}-${variationId}-${sizeId}`;

const formatBasicProduct = (item: BasicProductFragment | undefined) => {
  const formatedProduct = {
    item_id: item?.listingId,
    item_name: item?.name,
    item_category: item?.productName,
    price: item?.price,
  };
  return formatedProduct;
};

const formatBasicProductList = (productList: readonly BasicProductFragment[]) => {
  const items = productList.map((product: any) => {
    return formatBasicProduct(product);
  });
  return items;
};

const formatProduct = (product: ProductFragment | undefined) => {
  const formatedProduct = {
    item_id: replaceLowDashesWithDashes(product?.primaryProductSku),
    item_name: product?.title,
    item_category: product?.primaryProduct?.[0]?.productType,
    price: product?.primaryProduct?.[0]?.price,
  };
  return formatedProduct;
};

const formatProductList = (productList: readonly ProductFragment[]) => {
  const items = productList.map((product: any) => {
    return formatProduct(product);
  });
  return items;
};

const formatValidatedCartProducts = (productList: CartItemWithProductVariantSize[]) => {
  const items = productList.map((product: CartItemWithProductVariantSize) => {
    const formatedProduct = {
      item_id: skuConstructor(
        product.product.primaryProduct?.[0]?.teespringId?.toString() || '',
        product.cartItem.productId?.toString() || '',
        product.cartItem.variationId?.toString() || '',
        product.cartItem.sizeId?.toString() || ''
      ),
      item_name: product.product.title,
      item_category: product.product.primaryProduct?.[0]?.productType,
      price: product.product.primaryProduct?.[0]?.price,
      item_variant: `Color: ${product.variant?.color} | Size: ${product.size?.label}`,
    };
    return { ...formatedProduct, quantity: product.cartItem.quantity };
  });
  return items;
};
const formatCheckoutProducts = (checkoutProducts: any) => {
  const items = checkoutProducts.map((product: any) => ({
    item_id: skuConstructor(
      product?.primaryProduct?.[0]?.teespringId,
      product?.productId,
      product?.variationId,
      product?.sizeId
    ),
    item_name: product.productName,
    item_category: product.productType,
    quantity: product.quantity,
    price: product.price,
  }));
  return items;
};
function formatViewItemEvent(product: ProductFragment) {
  const sku = product?.primaryProductSku ? parseSku(product?.primaryProductSku) : undefined;
  const formatedProduct = {
    item_id:
      sku && product?.primaryProduct?.[0]?.teespringId
        ? skuConstructor(product.primaryProduct[0].teespringId, sku.productId, sku.variationId, sku.sizeId)
        : '',
    item_name: product?.title,
    item_category: product?.primaryProduct?.[0]?.productType,
    price: product?.primaryProduct?.[0]?.price,
  };
  return formatedProduct;
}

function formatAddToCartEvent(eventProperties: AddToCartPayload | RemoveFromCartPayload) {
  // price is only in AddToCartPayload
  const price = eventProperties && 'price' in eventProperties ? eventProperties.price : undefined;

  const formatedProduct = {
    item_id: skuConstructor(
      eventProperties?.product?.primaryProduct?.[0]?.teespringId?.toString() || '',
      eventProperties?.productId?.toString() || '',
      eventProperties?.variationId?.toString() || '',
      eventProperties?.sizeId?.toString() || ''
    ),
    item_name: eventProperties?.product?.title,
    item_category: eventProperties?.product?.primaryProduct?.[0]?.productType,
    ...(price ? { price } : undefined),
  };

  return [formatedProduct];
}

// from formatPurchaseEvent()
// teespring-custom-storefronts-react/src/utils/tracking/gtm/index.js
const formatPurchaseEventEcomPayload = ({ orders }: PurchasePayload, storeName: string) => {
  const shippingCost = convertToDollars(
    reduce(orders, (total, cartOrder) => total + (cartOrder?.shippingCost?.amount || 0), 0)
  );
  const taxCost = convertToDollars(reduce(orders, (total, cartOrder) => total + (cartOrder?.tax?.amount || 0), 0));
  const totalCost = convertToDollars(
    reduce(orders, (total, cartOrder) => total + (cartOrder?.totalCost?.amount || 0), 0)
  );
  const order = orders[0];
  return {
    transaction_id: order.cartId,
    currency: order?.totalCost?.currency || 'USD',
    shipping: shippingCost || 0,
    tax: taxCost || 0,
    value: totalCost || 0,
    coupon: order.promo,
    items: map(
      flatMap(orders, (cartOrder) => cartOrder?.items || []),
      (item) => {
        return {
          affiliation: 'TS Microstore',
          item_name: item.title,
          item_id: replaceLowDashesWithDashes(item.sku),
          price: convertToDollars(item?.price?.amount || 0),
          quantity: item.quantity,
          item_brand: storeName || '',
          item_variant: `Color: ${item.color} | Size: ${item.size}`,
          item_category: item.productType || '',
          coupon: order.promo,
        };
      }
    ),
  };
};

export const formatEventProperties = (eventType: keyof Metrics, eventProperties: any, context: MetricsContext) => {
  const baseEvent = {
    event: 'GTMecommerce',
    eventAction: eventKeys[eventType as keyof typeof eventKeys],
  };

  // special carve out for purchase for legacy store parity, and it appears to not be firing sometimes
  if (eventType === 'purchase') {
    return {
      ...baseEvent,
      ecommerce: {
        ...formatPurchaseEventEcomPayload(eventProperties, context.storeName || ''),
        ...context,
      },
    };
  }

  let items = [];
  if (eventKeys[eventType] === eventKeys.add_to_cart || eventKeys[eventType] === eventKeys.remove_from_cart) {
    items = formatAddToCartEvent(eventProperties);
  } else if (eventKeys[eventType] === eventKeys.view_item) {
    items = [formatViewItemEvent(eventProperties.product)];
  } else if (
    eventProperties.validatedCartProducts ||
    eventKeys[eventType] === eventKeys.add_shipping_info ||
    eventKeys[eventType] === eventKeys.add_payment_info
  ) {
    items = formatValidatedCartProducts(eventProperties.validatedCartProducts);
  } else if (eventProperties.products) {
    if (eventProperties.products?.[0]?.name && eventProperties.products?.[0]?.price) {
      items = formatBasicProductList(eventProperties.products);
    } else {
      items = formatProductList(eventProperties.products);
    }
  } else if (eventProperties.product) {
    items = [formatProduct(eventProperties.product)];
  } else if (eventProperties.checkoutProducts) {
    items = formatCheckoutProducts(eventProperties.checkoutProducts);
  } else if (eventProperties.item) {
    items = [formatBasicProduct(eventProperties.item)];
  }

  const selectProperties = {
    ...(eventProperties.currency && { currency: eventProperties.currency }),
    ...(eventProperties.price && { value: eventProperties.price }),
    ...(eventProperties.product && eventProperties.product.currency && { currency: eventProperties.product.currency }),
    ...(eventProperties.subTotal && { value: eventProperties.subTotal }),
    ...(items && items.length > 0 && { items }),
    ...(eventProperties.source && { item_list_name: eventProperties.source }),
    ...(eventProperties.value && { value: eventProperties.value }),
    ...(eventProperties.checkoutId && { transaction_id: eventProperties.checkoutId }),
    ...(eventProperties.paymentType && { payment_type: eventProperties.paymentType }),
    ...(eventProperties.errorId && { error_id: eventProperties.errorId }),
    ...(eventProperties.errorTitle && { error_title: eventProperties.errorTitle }),
  };

  if (!eventProperties.price && eventProperties.quantity && eventProperties.product?.primaryProduct?.[0]?.price) {
    const value = eventProperties.product?.primaryProduct?.[0]?.price
      ? eventProperties.product.primaryProduct[0].price * eventProperties.quantity
      : null;
    if (value) selectProperties.value = value;
  }

  if (eventType === 'view_item' || eventType === 'quickview_viewed') {
    selectProperties.view_type = eventType === 'view_item' ? 'full_product_detail_page' : 'quick_preview';
  }

  const eventBody = {
    ...baseEvent,
    ecommerce: {
      ...selectProperties,
      ...context,
    },
  };

  return eventBody;
};

export const track = <K extends keyof Metrics>(eventType: K, eventProperties: Metrics[K], context: MetricsContext) => {
  if (eventKeys[eventType as keyof typeof eventKeys]) {
    const gtmEvent = formatEventProperties(eventType, eventProperties, context);

    pushToDataLayer(gtmEvent);
  }
};

const gtm = { track };
export default gtm;
