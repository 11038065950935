/* Import analytics services */
import gtm from '@/lib/gtm';
import { StoreDataFragment } from '@/gql';
import { MEDIUM_SCREEN_SIZE, DESKTOP_SCREEN_SIZE } from '@/constants';
import { Metrics, AnalyticsServiceInitializer } from '@/types/metrics';

const servicesInitializers: AnalyticsServiceInitializer[] = [];
const services = [gtm];
const storedStoreData: StoreDataFragment[] = [];

const init = () => {
  servicesInitializers.forEach((service) => service.init && service.init());
};

const track = <K extends keyof Metrics>(eventType: K, eventProperties: Metrics[K], context: StoreDataFragment = {}) => {
  const storeData = context && context.id ? context : storedStoreData?.[0];
  const { id = null, sellerId = null, slug, name = null } = storeData || {};
  const width = window?.innerWidth || null;
  let screenWidth: string = '';
  if (width) {
    if (width >= DESKTOP_SCREEN_SIZE) {
      screenWidth = 'desktop';
    } else if (width >= MEDIUM_SCREEN_SIZE) {
      screenWidth = 'tablet';
    } else {
      screenWidth = 'mobile';
    }
  }
  const url = window?.location.href;
  services.forEach(
    (service) =>
      service.track &&
      service.track(eventType, eventProperties, {
        storeId: id,
        sellerId,
        storeName: name,
        storeSlug: slug ?? null,
        url,
        screenWidth,
      })
  );
};

const addContext = (storeData: StoreDataFragment) => {
  storedStoreData.push(storeData);
};

const removeContext = (storeData: StoreDataFragment) => {
  const idx = storedStoreData.lastIndexOf(storeData);
  if (idx >= 0) {
    storedStoreData.splice(idx, 1);
  }
};

const getStoreData = () => {
  return storedStoreData[0];
};

const analytics = {
  init,
  track,
  addContext,
  removeContext,
  getStoreData,
};
export default analytics;
